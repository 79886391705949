<template>
  <WrapBox>
    <div class="fourPage" ref="qudao" v-show="qudaoShow">
      <div class="background"></div>
      <div class="flex items-center justify-start content">
        <div class="left animate__animated animate__fadeInLeft">
          <img src="@/assets/imgs/online_consumption.png" alt="" />
        </div>
        <div class="right">
          <div
            class="smallTitle demibold animate__animated animate__bounceInRight"
          >
            {{ $t("跨境线上消费模式") }}
          </div>
          <p class="regular miniTitle" id="miniTitle">
            <span class="reveal" style="display: none" ref="textElement">
              {{
                $t(
                  "世界有多大，心就有多大，支持绑定多电子支付场景，GooglePlay、AppStore、Steam、 Amazon. 全宇宙都是你的消费乐园!"
                )
              }}</span
            >
          </p>
          <ul class="list">
            <li>
              <img src="@/assets/imgs/bg4-1.png" alt="" />
            </li>
            <li>
              <img src="@/assets/imgs/bg4-2.png" alt="" />
            </li>
            <li>
              <img src="@/assets/imgs/bg4-3.png" alt="" />
            </li>
            <li>
              <img src="@/assets/imgs/bg4-4.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </WrapBox>
</template>

<script>
import WrapBox from "./wrap/wrapBox.vue";
export default {
  name: "FourPage",
  components: { WrapBox },
  data() {
    return {
      qudaoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const text = this.$refs.textElement.innerText;
    const letters = text.split("");
    const container = document.getElementById("miniTitle");
    letters.forEach((letter, index) => {
      const span = document.createElement("span");
      span.classList.add("reveal");
      span.textContent = letter;

      setTimeout(() => {
        container.appendChild(span);
        span.classList.add(
          "animate__animated",
          "animate__bounceInRight",
          "animate__faster"
        );
      }, 100 * index);
    });
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b > a + c / 2) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fourPage {
  height: 100%;
  color: #fff;
  .background {
    position: fixed; /* 固定在视口 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/imgs/bg3.png");
    background-size: cover;
    background-position: center;
    animation: heartBeat 5s infinite; /* 心跳动画 */
    z-index: -1; /* 确保在其他内容后面 */
  }
  .content {
    position: relative; /* 确保内容在背景上方 */
    height: 100%;
    .left {
      flex: 1;
      height: 100%;
      margin-top: 136px;
      img {
        width: 676px;
        height: 80%;
      }
    }
    .right {
      flex: 1;
      height: 70%;
      margin: 161px 120px 202px 93px;
      .smallTitle {
        font-size: 44px;
        font-weight: 700;
        line-height: 58.34px;
        text-align: left;
        animation: bounceInRight 1s;
      }
      .miniTitle {
        margin-top: 30px;
        font-size: 22px;
        font-weight: 400;
        line-height: 29.17px;
        text-align: left;
        animation: bounceInRight 2s;
        color: #fff;
        opacity: 0.9;
      }
      .list {
        margin-top: 50px;
        li {
          width: 264px;
          height: 146px;
          display: inline-block;
          margin-right: 23px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:hover {
            transform: scale(1.05); /* 鼠标滑过时放大 */
          }
        }
      }
    }
  }
}
// @keyframes heartbeat {
//   0%,
//   100% {
//     transform: scale(1);
//   }
//   25% {
//     transform: scale(1.05); /* 放大效果 */
//   }
//   50% {
//     transform: scale(1); /* 恢复原始大小 */
//   }
//   75% {
//     transform: scale(1.05); /* 再次放大 */
//   }
// }
</style>
