<template>
  <WrapBox>
    <div class="onePage" ref="qudao">
      <!-- <Header /> -->
      <div class="main" v-show="qudaoShow">
        <div class="title animate__animated animate__fadeInUp">
          {{ $t("USDT区块链") }} X
          <span
            v-for="(char, index) in textArray"
            :key="index"
            class="hot"
            ref="chars"
            >{{ char }}</span
          >
          <!-- <span class="hot" id="hot">Master &Visa</span> -->
        </div>
        <div class="flex items-start justify-between middle">
          <div class="left">
            <p id="textElement">
              <span class="reveal" style="display: none" ref="textElement">{{
                $t("数字货币与传统金融的跨界交融")
              }}</span>
            </p>
            <p id="textElement1">
              <span class="reveal" style="display: none" ref="textElement1">{{
                $t("创新性金融整合应用体，财富跨界安全自由")
              }}</span>
            </p>
            <div type="primary" class="download demibold" @click="download">
              {{ $t("立即下载") }}
            </div>
          </div>
          <div
            class="flex flex-col items-start justify-start right"
            :style="currentLanguage == 'zh' ? 'height: 100%' : 'height: 80%'"
          >
            <div class="imgBox1 animate__animated animate__fadeInTopRight">
              <img src="@/assets/imgs/card1.png" alt="" class="img1" />
            </div>
            <div class="imgBox2 animate__animated animate__fadeInTopRight">
              <img src="@/assets/imgs/card2.png" alt="" class="img1" />
            </div>
            <div class="imgBox3 animate__animated animate__fadeInTopRight">
              <img src="@/assets/imgs/card3.png" alt="" class="img1" />
            </div>
          </div>
        </div>
      </div>
      <img
        src="@/assets/imgs/left.png"
        alt=""
        class="animate__animated animate__rotateIn leftImg"
      />
      <img
        src="@/assets/imgs/right.png"
        alt=""
        class="animate__animated animate__rotateIn rightImg"
      />
    </div>
  </WrapBox>
</template>

<script>
import WrapBox from "./wrap/wrapBox.vue";
// import Header from "./one/header.vue";
import gsap from "gsap";
// import { SplitText } from "split-text";
export default {
  name: "OnePage",
  components: { WrapBox },
  data() {
    return {
      qudaoShow: true,
      text: "Master &Visa",
      textArray: [],
      currentLanguage: "zh",
    };
  },
  mounted() {
    this.textArray = this.text.split(""); // 将文本分割为字符数组
    this.animateText(); // 调用动画函数
    const text = this.$refs.textElement.innerText;
    const letters = text.split("");
    const container = document.getElementById("textElement");
    letters.forEach((letter, index) => {
      const span = document.createElement("span");
      span.classList.add("reveal");
      span.textContent = letter;

      setTimeout(() => {
        container.appendChild(span);
        span.classList.add(
          "animate__animated",
          "animate__fadeInUp",
          "animate__faster"
        );
        if (index == letters.length - 1) {
          const text1 = this.$refs.textElement1.innerText;
          const letters1 = text1.split("");
          const container1 = document.getElementById("textElement1");
          letters1.forEach((letter, index) => {
            const span = document.createElement("span");
            span.classList.add("reveal");
            span.textContent = letter;

            setTimeout(() => {
              container1.appendChild(span);
              span.classList.add(
                "animate__animated",
                "animate__fadeInUp",
                "animate__faster"
              );
            }, 100 * index);
          });
        }
      }, 100 * index);
    });
    this.currentLanguage = localStorage.getItem("accept_language");
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 10, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (c <= a) {
          callback && callback();
        }
        if (b > c) {
          this.qudaoShow = false;
        } else {
          this.qudaoShow = true;
        }
      }
    },
    animateText() {
      const chars = this.$refs.chars;
      gsap.from(chars, {
        duration: 0.5,
        opacity: 0,
        y: 10,
        stagger: 0.1, // 每个字符之间的延迟
        ease: "power1.out",
      });
    },
    download() {
      window.location.href =
        "https://d1qv1lnrgfy0u7.cloudfront.net/apk/kendycard_ja.apk";
    },
  },
};
</script>

<style lang="scss" scoped>
.onePage {
  height: 100%;
  background: url("@/assets/imgs/bg1.png") no-repeat;
  font-size: 20px;
  color: #fff;
  position: relative;
  overflow: hidden;
  .leftImg {
    position: absolute;
    width: 352px;
    height: 467px;
    bottom: -233px;
    left: -30px;
    opacity: 0.6px;
    animation: rotate 20s linear infinite;
    z-index: 0;
  }
  .rightImg {
    position: absolute;
    width: 352px;
    height: 467px;
    bottom: 10%;
    right: -170px;
    opacity: 0.6px;
    animation: rotate 20s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .main {
    height: 100%;
    padding: 166px 120px 20px;
    .title {
      animation: fadeInUp 1s linear forwards;
    }
    .hot {
      background-image: linear-gradient(to right, #846c37, #5f4b1f 50%);
      // // background: linear-gradient(
      // //   135deg,
      // //   #846c37,
      // //   #5f4b1f,
      // //   // rgb(255, 0, 225),
      // //   // rgb(34, 0, 255),
      // //   // red
      // // );
      // // green;
      color: transparent;
      -webkit-background-clip: text;
      background-size: 200% 100%;
      animation: flowlight 5s forwards;
      // animation: fadeInUp 2s linear forwards;
      // animation: colorChange 3s forwards;
    }
    @keyframes flowlight {
      0% {
        filter: hue-rotate(0deg);
      }
      100% {
        color: #fff;
      }
    }
    .middle {
      margin-top: 8px;
      height: 100%;
      p {
        opacity: 0; /* 初始透明度 */
        transform: translateX(-20px); /* 初始位置 */
        animation: slideIn 1s forwards; /* 动画时长和效果 */
        font-size: 24px;
        line-height: 31.82px;
        text-align: left;
      }
      .left {
        margin-top: 65px;
        height: 100%;
        width: 50%;
        .download {
          position: absolute;
          z-index: 9;
          background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
          margin-top: 60px;
          min-width: 200px;
          padding: 0 50px;
          height: 80px;
          border-radius: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          line-height: 24px;
          text-align: left;
          cursor: pointer;
          &:hover {
            background: linear-gradient(180deg, #e2a63e 0%, #966728 100%);
            border: none;
            color: #fff;
          }
        }
      }
      .right {
        position: relative;
        flex: 1;
        .imgBox1,
        .imgBox2,
        .imgBox3 {
          img {
            width: 695.39px;
            height: 263.69px;
            border-radius: 32.38px 0px 0px 0px;
            border: 3.24px 0px 0px 0px;
          }
        }
        .imgBox1 {
          position: absolute;
          bottom: 50%;
          right: 10px;
          animation: fadeInTopRight 3s alternate;
          z-index: 111;
        }
        .imgBox2 {
          position: absolute;
          bottom: 30%;
          right: 20px;
          z-index: 110;
          animation: fadeInTopRight 2s alternate;
        }
        .imgBox3 {
          position: absolute;
          bottom: 15%;
          right: 30px;
          z-index: 100;
          animation: fadeInTopRight 1s alternate;
        }
      }
    }
  }
  @keyframes slideIn {
    to {
      opacity: 1; /* 最终透明度 */
      transform: translateX(0); /* 到达原位 */
    }
  }
  @keyframes colorChange {
    0% {
      color: #846c37;
    }
    50% {
      color: #5f4b1f;
    }
    80% {
      color: #846c37;
    }
    100% {
      color: #fff;
    }
  }
}
</style>
